// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ar-faq-js": () => import("./../../../src/pages/ar/faq.js" /* webpackChunkName: "component---src-pages-ar-faq-js" */),
  "component---src-pages-ar-js": () => import("./../../../src/pages/ar.js" /* webpackChunkName: "component---src-pages-ar-js" */),
  "component---src-pages-ar-privacy-policy-js": () => import("./../../../src/pages/ar/privacy-policy.js" /* webpackChunkName: "component---src-pages-ar-privacy-policy-js" */),
  "component---src-pages-ar-shipping-js": () => import("./../../../src/pages/ar/shipping.js" /* webpackChunkName: "component---src-pages-ar-shipping-js" */),
  "component---src-pages-ar-terms-js": () => import("./../../../src/pages/ar/terms.js" /* webpackChunkName: "component---src-pages-ar-terms-js" */),
  "component---src-pages-en-contact-us-js": () => import("./../../../src/pages/en/contact-us.js" /* webpackChunkName: "component---src-pages-en-contact-us-js" */),
  "component---src-pages-en-cookie-policy-js": () => import("./../../../src/pages/en/cookie-policy.js" /* webpackChunkName: "component---src-pages-en-cookie-policy-js" */),
  "component---src-pages-en-faq-js": () => import("./../../../src/pages/en/faq.js" /* webpackChunkName: "component---src-pages-en-faq-js" */),
  "component---src-pages-en-gift-cards-js": () => import("./../../../src/pages/en/gift-cards.js" /* webpackChunkName: "component---src-pages-en-gift-cards-js" */),
  "component---src-pages-en-invite-freinds-js": () => import("./../../../src/pages/en/invite-freinds.js" /* webpackChunkName: "component---src-pages-en-invite-freinds-js" */),
  "component---src-pages-en-js": () => import("./../../../src/pages/en.js" /* webpackChunkName: "component---src-pages-en-js" */),
  "component---src-pages-en-order-js": () => import("./../../../src/pages/en/order.js" /* webpackChunkName: "component---src-pages-en-order-js" */),
  "component---src-pages-en-privacy-policy-js": () => import("./../../../src/pages/en/privacy-policy.js" /* webpackChunkName: "component---src-pages-en-privacy-policy-js" */),
  "component---src-pages-en-shipping-js": () => import("./../../../src/pages/en/shipping.js" /* webpackChunkName: "component---src-pages-en-shipping-js" */),
  "component---src-pages-en-size-guide-js": () => import("./../../../src/pages/en/size-guide.js" /* webpackChunkName: "component---src-pages-en-size-guide-js" */),
  "component---src-pages-en-status-js": () => import("./../../../src/pages/en/status.js" /* webpackChunkName: "component---src-pages-en-status-js" */),
  "component---src-pages-en-terms-js": () => import("./../../../src/pages/en/terms.js" /* webpackChunkName: "component---src-pages-en-terms-js" */)
}

